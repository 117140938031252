import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { Flex, Box } from 'rebass';
import Container from "../../components/elements/Container";
import { H3, Bold, Title } from "../../components/elements";
import { theme } from "../../theme";
import Image from "../../components/elements/Image";
import { down } from 'styled-breakpoints';
import Heading from "../../components/elements/Heading";
import parse from 'html-react-parser';
import { getAboutImage } from "../../utils/common";
import StyledRichText from "../../components/elements/StyledRichText";
interface AboutProps {
  phoneNumber?: string;
  aboutRecord?: any;
}

const AboutSection = styled.section`
  text-align: center;
  padding: ${props => props.theme.spacing[4]}rem 0;
`;

const AboutContent = styled.div`
font-weight: ${(props: any) => (props.theme.fontWeight.light)};
font-size: .9rem;
line-height: 30px !important;
margin-top: 15px;
color: ${(props: any) => (props.theme.colors.mediumGray)};
text-align: left; 
}
  `;

const AboutWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  img{
    display: block;
    max-width: 100%;
}
  `;

const Strip = styled.div`
  width: 100px;
  margin-bottom: 120px;
  background-color: ${(props: any) => (props.theme.colors.blue)};
  display: flex;
  justify-content: center;
  align-items: center;
  min-height:500px;
  ${down('md')}{
    width: 100%;
    min-height:100px;
    margin-bottom: 10px;
  }
  `;

const StripText = styled.h2`
  transform: rotate(-90deg);
  color: ${(props: any) => (props.theme.colors.white)};
  text-align: center;
  white-space: nowrap;
  font-family: ${(props: any) => (props.theme.font.families.poppins)};
  font-weight: ${(props: any) => (props.theme.fontWeight.bold)};
  font-size: 56px;
  line-height: 54px;
  ${down('lg')}{
    font-weight: ${(props: any) => (props.theme.fontWeight.semibold)};
    font-size: 38px;
    line-height: 40px;
  }
  ${down('md')}{
    font-weight: ${(props: any) => (props.theme.fontWeight.semibold)};
  font-size: ${(props: any) => (props.theme.fontSize['3xl'])};
  line-height: 34px;
  transform: rotate(0deg);
  white-space:normal;
  }
  span{
    font-family: ${(props: any) => (props.theme.font.families.poppins)};
    font-weight: ${(props: any) => (props.theme.fontWeight.light)};
    font-size: 30px;
    line-height: 25px;
    ${down('lg')}{
      font-weight: ${(props: any) => (props.theme.fontWeight.light)};
      font-size: 20px;
      line-height: 22px;
    }
    ${down('md')}{
      font-weight: ${(props: any) => (props.theme.fontWeight.light)};
      font-size: ${(props: any) => (props.theme.fontSize.lg)};
      line-height: 22px;
    }
  }
`;

const MainImg = styled.div`
padding-left: 25px;
flex: 0 0 calc(100% - 100px);
width: calc(100% - 100px);
max-width: 100%;
min-height: 400px;
.img1{
  height: 100%;
        object-fit: cover;
}
${down('md')}{
  width: 100%;
  
padding-left: 0;
flex: 0 0 100%;
width: 100%;
}
  }
`;

const SubImg = styled.div`
flex: 0 0 70%;
width: 70%;
max-width: 70%;
margin-top: -120px;
padding: 1rem 1rem 0.5rem 0.5rem;
background: ${(props: any) => (props.theme.colors.white)};
${down('md')}{
  flex: 0 0 70%;
        width: 70%;
        max-width: 100%;
        margin-top: -60px;
        padding: 0.5rem;
        background: ${(props: any) => (props.theme.colors.white)};
    }
`;

const About: FC<AboutProps> = ({ aboutRecord }) => {
  const id = aboutRecord?.data?.id;
  const [aboutImageData, setAboutImageData] = useState<any>(null);
  useEffect(() => {
    const aboutDataReduced = getAboutImage(aboutRecord?.data?.attributes?.media);
    setAboutImageData(aboutDataReduced);
  }, [id]);

  const desc = aboutRecord?.data?.attributes?.desc ?? '';
  return (
    <AboutSection id="about">
      <Container>

        <Flex flexWrap={['wrap', 'wrap', 'wrap', 'nowrap']} justifyContent='center' >

          <Box m={[2, 3]}
            sx={{
              width: '55%',
              '@media screen and (max-width: 64em)': {
                width: '100%',
                margin: '0',
              }
            }}>

            <AboutWrap>
              <Strip>
                <StripText>{aboutRecord?.data?.attributes?.exp} <span>years of experience</span></StripText>
              </Strip>
              <MainImg>
                <Image
                  src={aboutImageData && aboutImageData[0]?.src}
                  alt={aboutImageData && aboutImageData[0]?.alt}
                  apiImageUrl={true}
                  style={{ maxWidth: '100%', maxHeight: '600px' }}
                  className="img1"
                />
              </MainImg>
              <SubImg>
                <Image
                  src={aboutImageData && aboutImageData[1]?.src}
                  alt={aboutImageData && aboutImageData[1]?.alt}
                  apiImageUrl={true}
                  style={{ maxWidth: '100%', maxHeight: '300px', width: '100%', objectFit: 'cover', }}
                />
              </SubImg>
            </AboutWrap>
          </Box>

          <Box m={[2, 3]}
            sx={{
              width: '45%',
              '@media screen and (max-width: 64em)': {
                width: '100%',
              }
            }} >
            <Title textColor={theme.colors.blue} fontWeight={theme.fontWeight.light}>{"About Us"}</Title>
            <Heading textAlignMob={'left'} textAlign={'left'} fontSize={'2rem'} lineHeight={1} boldFontWeight={theme.fontWeight.bold} textFontWeight={theme.fontWeight.light} textColor={theme.colors.lightBlack} text={"Your Trusted"} boldText={"Pool Builder"} boldTextColor={theme.colors.blue} />
            <StyledRichText>
              <AboutContent>
                {parse(desc)}
              </AboutContent>
            </StyledRichText>
            <Flex alignItems='center' my={3}
              sx={{
                '@media screen and (max-width: 64em)': {
                  justifyContent: "center",
                }
              }} >

              <Image
                src={"temp.svg"}
                alt="logo"
              />
              <Title lineHeight={1} textColor={theme.colors.lightBlack} fontWeight={theme.fontWeight.semibold}>Our company mission -</Title>
            </Flex>
            <H3 lineHeight={1} textAlignMob={'center'}><Bold textColor={theme.colors.blue} style={{ fontStyle: "italic" }}>“Bringing Imagination to Life"</Bold> </H3>
          </Box>

        </Flex>
      </Container>
    </AboutSection>
  );
};

export default About;